<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      max-width="1000px"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ model.id ? 'Edición' : 'Creación' }} Lugar</span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row mt-1">
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  vid="name"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Área"
                  vid="area"
                  rules="required|max:4"
                >
                  <v-text-field
                    v-model="model.area"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Área"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Color"
                  vid="color"
                  rules="required|max:7"
                >
                  <v-text-field
                    v-model="model.color"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Color"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Tamaño"
                  vid="size"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.size"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Tamaño"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Ancho imagen"
                  vid="planeWidth"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.planeWidth"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Ancho imagen (Debe ser 1200px)"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Alto imagen"
                  vid="planeHeight"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.planeHeight"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Alto imagen"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-0">
                <validation-provider
                  vid="description"
                  v-slot="{ errors }"
                  name="Descripción"
                  rules="min:0|max:600">
                  <v-textarea
                    counter="600"
                    outlined
                    rows="2"
                    type="text"
                    :error-messages="errors"
                    clear-icon="mdi-close-circle"
                    label="Escribe una breve descripción de la feria"
                    v-model="model.description"
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-0 my-0 px-8">
                <v-switch
                  v-model="model.isActive"
                  label="¿Activa?"
                />
              </div>
              <div class="col-12 col-md-4 py-0">
                <h3>Con cuadrícula</h3>
                <file-chooser
                  ref="fileChooser"
                  @input="onLoadAdminPlaneImageUrl"
                  :options="{
                    height: '190px',
                    description: 'Haz clic para cargar el plano del lugar con cuadrícula',
                    regex: /\.(jpg|jpeg|png|gif)$/,
                    error: 'Solo se permiten imagenes',
                    fileSize: 2,
                  }"
                >
                </file-chooser>
              </div>
              <div class="col-12 col-md-4 py-0">
                <h3>Sin cuadrícula</h3>
                <file-chooser
                  ref="fileChooser2"
                  @input="onLoadUserPlaneImageUrl"
                  :options="{
                    height: '190px',
                    description: 'Haz clic para cargar el plano del lugar sin cuadrícula',
                    regex: /\.(jpg|jpeg|png|gif)$/,
                    error: 'Solo se permiten imagenes',
                    fileSize: 2,
                  }"
                >
                </file-chooser>
              </div>
              <div class="col-12 col-md-4 py-0">
                <h3>Con stands para App</h3>
                <file-chooser
                  ref="fileChooser3"
                  @input="onLoadAppPlaneImageUrl"
                  :options="{
                    height: '190px',
                    description: 'Haz clic para cargar el plano general del lugar con stands para App',
                    regex: /\.(jpg|jpeg|png|gif)$/,
                    error: 'Solo se permiten imagenes',
                    fileSize: 2,
                  }"
                >
                </file-chooser>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="savePlace"
          >
            Guardar
          </v-btn>
          <v-btn
            color="primary"
            text
            v-if="model.id"
            @click="manageStands"
          >
            Administrar Stands
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deletePlace"
            v-if="model.id"
          >
            Eliminar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import FileChooser from '@/components/file-chooser/FileChooser'
import { PLACE_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  components: {
    FileChooser
  },
  data () {
    return {
      isActive: false,
      model: {
        adminPlaneImageUrl: null,
        area: null,
        color: null,
        description: null,
        fairId: null,
        isActive: null,
        name: null,
        planeHeight: null,
        planeWidth: null,
        pinIconUrl: null,
        size: null,
        userPlaneImageUrl: null,
        appPlaneImageUrl: null,
        xCoordinate: null,
        yCoordinate: null,
        year: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        adminPlaneImageUrl: null,
        area: null,
        color: null,
        description: null,
        fairId: null,
        name: null,
        planeHeight: null,
        planeWidth: null,
        pinIconUrl: null,
        size: null,
        userPlaneImageUrl: null,
        xCoordinate: null,
        yCoordinate: null,
        year: null
      }
      this.$refs.fileChooser.setError(null)
      this.$refs.fileChooser2.setError(null)
    },
    async deletePlace () {
      await this.remove(PLACE_URL, this.model.id).then(() => {
        this.close()
        this.$emit('onClose')
      })
    },
    manageStands () {
      this.$router.push({
        name: 'stands',
        params: {
          fairId: this.model.fairId,
          id: this.model.id
        }
      })
      this.close()
    },
    onLoadAdminPlaneImageUrl (file) {
      this.model.adminPlaneImageUrl = file
    },
    onLoadUserPlaneImageUrl (file) {
      this.model.userPlaneImageUrl = file
    },
    onLoadAppPlaneImageUrl (file) {
      this.model.appPlaneImageUrl = file
    },
    open (place, fairId) {
      this.isActive = true
      this.$nextTick(() => {
        this.model.fairId = fairId
        this.model.xCoordinate = 200
        this.model.yCoordinate = 600
        if (place) {
          this.model = place
          this.$refs.fileChooser.setImage(place.adminPlaneImageUrl)
          this.$refs.fileChooser2.setImage(place.userPlaneImageUrl)
          this.$refs.fileChooser3.setImage(place.appPlaneImageUrl)
        } else {
          this.$refs.fileChooser.setImage(null)
          this.$refs.fileChooser2.setImage(null)
          this.$refs.fileChooser3.setImage(null)
        }
      })
    },
    savePlace () {
      if (!this.model.adminPlaneImageUrl) {
        this.$refs.fileChooser.setError('El plano general del sitio es requerido')
      }
      if (!this.model.userPlaneImageUrl) {
        this.$refs.fileChooser2.setError('El plano general del usuario es requerido')
      }
      if (!this.model.appPlaneImageUrl) {
        this.$refs.fileChooser3.setError('El plano general de la app es requerido')
      }
      this.executeWithFormValidation(() => {
        if (this.model.adminPlaneImageUrl && this.model.userPlaneImageUrl) {
          const formData = this.getFormData()
          formData.append('adminPlaneImageUrl', this.model.adminPlaneImageUrl)
          formData.append('userPlaneImageUrl', this.model.userPlaneImageUrl)
          formData.append('appPlaneImageUrl', this.model.appPlaneImageUrl)
          this.create(PLACE_URL, formData).then(() => {
            this.close()
            this.$emit('onClose')
          })
        }
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'PlaceModal'
}
</script>
