var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fair)?_c('fair-layout',{attrs:{"fair-id":_vm.fair.id},scopedSlots:_vm._u([{key:"banner",fn:function(){return [(_vm.fair)?_c('banner',{attrs:{"description":"Administrar espacios","title":_vm.fair.name,"type":"banner--admin","options":{padding: '30px'}}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"pt-3 px-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-end"},[_c('v-btn',{staticClass:"text--primary elevation-0 text-none",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openPlaceModal()}}},[_vm._v(" Crear lugar ")]),_c('v-btn',{staticClass:"white--text text-none elevation-0 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.back}},[_vm._v(" Regresar ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-center"},[_c('div',{staticClass:"overflow-auto"},[_c('konva-stage',{ref:"stage",attrs:{"config":{ width: 1200, height: 678 }}},[_c('konva-layer',{ref:"layer"},[_c('konva-image',{attrs:{"config":{ image: _vm.fairPlaneImg }}}),_vm._l((_vm.places),function(place){return _c('konva-group',{key:place.id,attrs:{"config":{
                x: Number(place.xCoordinate),
                y: Number(place.yCoordinate),
              },"draggable":true},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.openPlaceModal(place)},"dragend":function($event){return _vm.saveNewCoordinates($event, place)}}},[_c('konva-image',{attrs:{"config":{
                  image: _vm.fairPinIconImg,
                  width: 40,
                  height: 40,
                  x: -20,
                  y: -40,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(0 - 75),
                  y: Number(0),
                  width: 150,
                  height: 20,
                  stroke: place.color,
                  fill: '#fff',
                  strokeWidth: 1,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: -100,
                  y: 0,
                  text: place.name,
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fill: '#555',
                  width: 200,
                  padding: 5,
                  align: 'center',
                }}})],1)})],2)],1)],1)])]),_c('place-modal',{ref:"placeModal",on:{"onClose":_vm.onClose}})],1)]},proxy:true}],null,false,3826119365)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }